import { useCallback, useState, FormEvent, useRef, useEffect, useMemo } from 'react'
import IconDelete from '@haiper/icons-svg/icons/outline/delete.svg'
import { isNil, cloneDeep, omit, pick, merge } from 'lodash-es'
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import {
  generate,
  inpaint,
  generateBB8,
  repaint,
  extend,
  upscale,
  generateAfc,
  postTemplateJob,
  gen2Text2Video,
  gen2Image2Video,
  generateImg,
  gen2Text2Image,
} from '@/service/job.service'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import Button from '@/components/button'
import {
  auth0SignInAtom,
  creationCoolDownAtom,
  creationInputAtom,
  creationInputPrivateModeAtom,
  enableAfcAtom,
  illegalSubmissionDialogOpenAtom,
  jobsRefreshKeyAtom,
  loginDialogOpenAtom,
  productHuntDialogOpenAtom,
  respectTemplateClientNameAtom,
  showCreationModeTutorialAtom,
} from '@/atoms'
import {
  GenerationParams,
  GenerationResult,
  GenerationSetting,
  PromiseOrNot,
  CreationModeEnum,
  SamCallbackData,
  InpaintingParams,
  InnerUploadFile,
  PoNVoid,
  ExtendGenerationParams,
  UpscaleGenerationParams,
  ThreeStage,
  CreationModeEnumOrUsecaseEnum,
  TemplateGenerationParams,
  TemplateDetail,
  ModelVersion,
  AnyObject,
} from '@/types'
import {
  getImageResolution,
  getVideoResolution,
  preventDefault,
  cls,
  withFirebaseBucketPrefix,
  getVideoDuration,
  getNextExtendDuration,
  isValidSeed,
  whisper,
  getLocalStorage,
  setLocalStorage,
} from '@/utils'
import { useClickOutside } from '@/hooks/useClickOutside'
import { toast } from '@/components/ui/use-toast'
import { Textarea } from './textarea'
import {
  AFC_REQUIRE_SAME_RESOLUTION,
  DEFAULT_COOL_DOWN_TIME,
  ASPECT_RATIO_MAP,
  DEFAULT_IMAGE_RESOLUTION,
  DEFAULT_RESOLUTION,
  DEFAULT_ASPECT_RATIO,
  CACHE_KEY_PRODUCTHUNT_DIALOG,
} from '@/constants'
import CreationModeDialog from './creation-mode/modal'
import { CreationSetting } from './creation-setting'
import VisibilitySetting from './visibility-setting'
import { ButtonGenerate } from './button-generate'
import ChevronTop from '@haiper/icons-svg/icons/outline/chevron-large-top.svg'
import ChevronDown from '@haiper/icons-svg/icons/outline/chevron-large-down.svg'
import dayjs from 'dayjs'
import useCreationSettings from '@/hooks/useCreationSettings'
import { nanoid } from 'nanoid'
import LoginMask from '../login-mask'
import useActivePlan from '@/hooks/useActivePlan'
import useCredit from '@/hooks/useCredit'
import { useCachedSwitches } from '@/hooks/useSwitches'
import { confirm } from '../dialog'
import ModelVersionPicker from './model-version'
import { CreationInputControlsProps, MAX_LENGTH, placeholderMap } from './controls/common'
import T2vControls from './controls/t2v'
import I2vControls from './controls/i2v'
import V2vControls from './controls/v2v'
import UpscaleControls from './controls/upscale'
import ExtendControls from './controls/extend'
import T2iControls from './controls/t2i'
import CreationInputMask from './mask'
import useCurrentCreationMode from '@/hooks/useCurrentCreationMode'
import useCreationSettingVisibility from '@/hooks/useCreationSettingVisibility'
import useDurationOptions from '@/hooks/useDurationOptions'
import TemplateControls from './controls/template'
import { publicUpload } from '@/service/upload.service'
import useActiveTemplate from '@/hooks/useActiveTemplate'
import useSuggestions from '@/hooks/useSuggestions'
import useActiveTemplateInputs from '@/hooks/useActiveTemplateInputs'
import CreationModeTutorialButton from './creation-mode/tutorial-button'
import CreationModeTutorial from './creation-mode/tutorial'
import useTutorialStates from '@/hooks/useTutorialStates'
import { Switch } from '@/components/ui/switch'
import Link from '../link'
import useDefaultSettings from '@/hooks/useDefaultSettings'
import useSurvey from '@/hooks/useSurvery'

interface InputSchema {
  prompt?: string
  fileId?: string | null
  mode: CreationModeEnumOrUsecaseEnum
  uploading?: boolean
}

const modesRequiresFile: CreationModeEnumOrUsecaseEnum[] = [
  CreationModeEnum.Repaint,
  CreationModeEnum.Extend,
  CreationModeEnum.Upscale,
]

const modesRequiresKeyframe: CreationModeEnumOrUsecaseEnum[] = [CreationModeEnum.Animate, CreationModeEnum.AnimateHD]

export interface CreationInputProps {
  className?: string
  containerClassName?: string
  toolbarDirection?: 'up' | 'down'
  placeholder?: string
  onSubmit?: (params: GenerationParams) => PromiseOrNot<GenerationResult>
  onGenerate?: (creationId: string) => PoNVoid
}

const promptAtom = atom<string>('')
const creationSettingsAtom = atom<GenerationSetting>({})
const mlArgsAtom = atom<AnyObject>({})
const styleAtom = atom<string>('')
const fileAtom = atom<InnerUploadFile | null>(null)
const uploadingAtom = atom<boolean>(false)
const keyframeFilesAtom = atom<ThreeStage<InnerUploadFile | null>>({
  first: null,
  second: null,
  third: null,
})
const keyframeFilesUploadingAtom = atom<ThreeStage<boolean>>({
  first: false,
  second: false,
  third: false,
})

const templateInputsAtom = atom<Record<string, any>>({})
const enhancePromptAtom = atom<boolean>(true)

export default function CreationInput({
  className,
  containerClassName,
  toolbarDirection,
  placeholder,
  onSubmit,
  onGenerate,
}: CreationInputProps) {
  const [creationInput, setCreationInput] = useAtom(creationInputAtom)
  const hasSignIn = useAtomValue(auth0SignInAtom)
  const { creation, expanded, focusing } = creationInput
  const { data: activeTemplate, id: activeTemplateId } = useActiveTemplate()
  const activeTemplateCategory = useMemo(() => activeTemplate?.category ?? '', [activeTemplate])

  const illegalSubmissionDialogOpen = useAtomValue(illegalSubmissionDialogOpenAtom)

  const { tryOpenSurvey } = useSurvey()

  const [showCreationModeTutorial, setShowCreationModeTutorial] = useAtom(showCreationModeTutorialAtom)
  const respectTemplateClientName = useAtomValue(respectTemplateClientNameAtom)

  const [enhancePrompt, setEnhancePrompt] = useAtom(enhancePromptAtom)

  const realShowCreationModeTutorial =
    showCreationModeTutorial && expanded && activeTemplateId && !!(activeTemplate as TemplateDetail)?.tutorial

  const tutorialKey = useMemo(() => {
    const tutorial = (activeTemplate as TemplateDetail)?.tutorial ?? ''
    const id = activeTemplate?.template_id

    if (!tutorial || !id) {
      return ''
    }

    // use the embedded tutorial id if available in the tutorial content
    let result = `template:${activeTemplate?.template_id}`
    const firstLine = tutorial
      .split('\n')
      .find((line) => line.trim().length > 0)
      ?.trim()

    const match = firstLine?.match(/\(id:\s*([a-zA-Z0-9_:-]+)\)/)

    if (match) {
      result = match[1]
    }

    return result
  }, [activeTemplate])
  const { read: readTutorial, isReaded: isTutorialReaded } = useTutorialStates(tutorialKey)

  useEffect(() => {
    if (activeTemplateId) {
      setShowCreationModeTutorial(!isTutorialReaded)
    }
  }, [isTutorialReaded, activeTemplateId, setShowCreationModeTutorial])

  const { data: currentCreationMode } = useCurrentCreationMode()
  const mode = currentCreationMode?.mode ?? ''
  const fileType = useMemo(() => {
    const newFileType = currentCreationMode?.tags.includes('video')
      ? 'video'
      : currentCreationMode?.tags.includes('image')
        ? 'image'
        : ''

    return newFileType
  }, [currentCreationMode])
  const [prompt, setPrompt] = useAtom(promptAtom)
  const [settings, setSettings] = useAtom(creationSettingsAtom)
  const [mlArgs, setMLArgs] = useAtom(mlArgsAtom)

  const { data: switches } = useCachedSwitches()
  const showKeyframeConditioning = mode === CreationModeEnum.AnimateHD && !!switches?.keyframe_conditioning

  const setLoginDialogOpen = useSetAtom(loginDialogOpenAtom)
  const [style, setStyle] = useAtom(styleAtom)

  const { data: activePlan } = useActivePlan()
  const isFreePlan = !activePlan || activePlan?.is_free
  const canGeneratePrivate = !!activePlan?.allow_private_generation

  const cooldownTooltip = useMemo(() => {
    if (isFreePlan) {
      return (
        <span className='text-body-md font-medium'>
          <Link href='/membership' className='text-band-400 hover:text-band-300'>
            Upgrade
          </Link>
          <span className='pl-1 text-text-on-color'>to shorten wait time</span>
        </span>
      )
    }
    return null
  }, [isFreePlan])

  const checkAuth = useCallback(() => {
    if (!hasSignIn) {
      setLoginDialogOpen(true)
    }
  }, [hasSignIn, setLoginDialogOpen])

  const setJobsRefreshKey = useSetAtom(jobsRefreshKeyAtom)
  const refreshJobs = useCallback(() => {
    setJobsRefreshKey(nanoid())
  }, [setJobsRefreshKey])

  const [file, setFile] = useAtom(fileAtom)

  const [loading, setLoading] = useState(false)
  const loadingRef = useRef<boolean>(false)

  const ref = useRef<HTMLFormElement | null>(null)
  const samRef = useRef<SamCallbackData>()

  const { isBelowMd } = useBreakpoint('md')

  const [uploading, setUploading] = useAtom(uploadingAtom)
  const [innerEnableAfc, setEnableAfc] = useAtom(enableAfcAtom)
  const setProductHuntDialogOpen = useSetAtom(productHuntDialogOpenAtom)

  // disable AFC for version 2
  const enableAfc = innerEnableAfc && creationInput.version !== ModelVersion.TWO

  const [templateInputs, setTemplateInputs] = useAtom(templateInputsAtom)

  const lastTemplateIdRef = useRef<string | null>(null)
  const lastTemplateCategoryRef = useRef<string | null>(null)

  useEffect(() => {
    // reset template inputs when template category changes
    if (activeTemplateCategory !== lastTemplateCategoryRef.current) {
      if (lastTemplateCategoryRef.current) {
        setTemplateInputs({})
      }
      lastTemplateCategoryRef.current = activeTemplateCategory
    }
  }, [activeTemplateCategory, setTemplateInputs])

  useEffect(() => {
    // reset template inputs when template category changes
    if (activeTemplate?.template_id !== lastTemplateIdRef.current) {
      if (activeTemplate?.category === 'basic' && lastTemplateIdRef.current) {
        // reset template inputs when template changes if it's a basic template. (ref: PROD-1119)
        setTemplateInputs({})
      }
      lastTemplateIdRef.current = activeTemplate?.template_id ?? null
    }
  }, [activeTemplate, setTemplateInputs])

  const [keyframeFiles, setKeyframeFiles] = useAtom(keyframeFilesAtom)

  const [keyframesUploading, setKeyframesUploading] = useAtom(keyframeFilesUploadingAtom)

  const creationInputPrivateMode = useAtomValue(creationInputPrivateModeAtom)

  const [cooldown, setCoolDown] = useAtom(creationCoolDownAtom)
  const { data: creationSettings } = useCreationSettings()
  const { refreshCredit } = useCredit()

  const coolDownTimeInConfig = isFreePlan
    ? creationSettings?.cool_down_time
    : Math.min(
        creationSettings?.cool_down_time_paid ?? creationSettings?.cool_down_time ?? DEFAULT_COOL_DOWN_TIME,
        creationSettings?.cool_down_time ?? DEFAULT_COOL_DOWN_TIME,
      )

  const coolDownTime = coolDownTimeInConfig ?? DEFAULT_COOL_DOWN_TIME

  const {
    showAspectRatio,
    validKeys,
    showStyles,
    showCameraMovement,
    showSeed,
    showDuration,
    showFakeDuration,
    showFastGen,
    showMotionMode,
    showEnhancePrompt,
  } = useCreationSettingVisibility()

  // const useNumbericAspectRatio = !showAspectRatio && !activeTemplate && isProduction

  const disableInput = CreationModeEnum.Upscale === mode
  const hideTextArea = Boolean(isBelowMd && disableInput && expanded)
  const suggestions = useSuggestions()

  const promptSuffix = useMemo(() => {
    if (!showStyles) {
      return ''
    }
    const selectedStyle = suggestions?.find((item) => item.key === style)
    return selectedStyle?.prompt_append ?? ''
  }, [suggestions, style, showStyles])

  const pickedStyle = useMemo(
    () => activeTemplate?.styles?.find((item) => item.id === style) ?? activeTemplate?.styles?.[0],
    [activeTemplate, style],
  )

  const { data: activeTemplateInputWidgets, defaultValues: activeTemplateDefaultValues } = useActiveTemplateInputs()

  const comfyTemplateExtraParams = useMemo(() => {
    if (!activeTemplate) {
      return null
    }
    return {
      ...activeTemplateDefaultValues,
      ...pickedStyle?.params,
    }
  }, [activeTemplate, pickedStyle, activeTemplateDefaultValues])

  useEffect(() => {
    if (isBelowMd) {
      if (expanded) {
        disableBodyScroll(document.body)
      } else {
        enableBodyScroll(document.body)
      }
    }
  }, [expanded, isBelowMd])

  const getDurationByUrl = useCallback(
    async (url: string | undefined) => {
      if (!url) {
        return 0
      }
      const duration = await getVideoDuration(url)
      const maxDuration = mode === CreationModeEnum.Upscale ? 12 : 4
      const formattedDuration =
        mode === CreationModeEnum.Extend ? getNextExtendDuration(duration) : Math.round(duration)
      const realDuration = Math.min(maxDuration, formattedDuration)
      return realDuration
    },
    [mode],
  )

  const setDurationByUrl = useCallback(
    async (url: string | undefined) => {
      if (!url || !showDuration) {
        // setSettings((prev) => ({ ...prev, duration: 0 }))
        return
      }
      const duration = await getDurationByUrl(url)
      if (duration > 0) {
        setSettings((prev) => ({ ...prev, duration }))
      }
    },
    [setSettings, showDuration, getDurationByUrl],
  )

  const dismissTutorial = useCallback(() => {
    setShowCreationModeTutorial(false)
    readTutorial()
  }, [setShowCreationModeTutorial, readTutorial])

  const fold = useCallback(() => {
    setCreationInput((prev) => {
      if (!prev.expanded) {
        return prev
      }
      return {
        ...prev,
        expanded: false,
      }
    })
    dismissTutorial()
  }, [setCreationInput, dismissTutorial])

  const unfold = useCallback(() => {
    setCreationInput((prev) => {
      if (prev.expanded) {
        return prev
      }
      return {
        ...prev,
        expanded: true,
      }
    })
  }, [setCreationInput])

  const clickOutsideOptions = useMemo(() => {
    return {
      ref,
      boundElements: [],
      excludeSelector: ['[data-component="creation-input"][data-outside="false"]', '[role="dialog"]'],
      onClickOutside: fold,
    }
  }, [ref, fold])

  useClickOutside(clickOutsideOptions)

  const outputVideo: string = (creation as any)?.output_video
  const fileId = file?.fileId || outputVideo

  const hasKeyframeFile = Boolean(keyframeFiles.first || keyframeFiles.second || keyframeFiles.third)
  const keyframeFileUploading = keyframesUploading.first || keyframesUploading.second || keyframesUploading.third

  const keyframeRequired = mode === CreationModeEnum.AnimateHD
  const fileRequired = currentCreationMode?.tags.includes('image') || currentCreationMode?.tags.includes('video')

  const uploadRequired = !keyframeRequired && fileRequired

  const hasEmptyTemplateFields: boolean = useMemo(() => {
    const defaultInputsInStyle = pickedStyle?.params ?? {}
    const whitelist = ['seed']

    for (const field of activeTemplateInputWidgets ?? []) {
      if (
        !templateInputs[field.key] &&
        !defaultInputsInStyle[field.key] &&
        !whitelist.includes(field.key) &&
        !activeTemplateDefaultValues[field.key]
      ) {
        return true
      }

      if (field.widget === 'sam' && !(templateInputs[field.key] as SamCallbackData)?.maskImgUrl) {
        return true
      }
    }
    return false
  }, [activeTemplateInputWidgets, templateInputs, pickedStyle, activeTemplateDefaultValues])

  const formatTemplateInputs = useCallback(
    async (templateInputs: Record<string, any>) => {
      const result: Record<string, any> = {}
      for (const item of activeTemplateInputWidgets) {
        const isUploadWidget = item.widget === 'image_input' || item.widget === 'video_input'
        const rawValue = templateInputs[item.key]
        if (isUploadWidget) {
          // const uploadValueKey = isUploadWidget ? (item.type === 'gcs_key' ? 'fileId' : 'url') : ''
          const uploadValueKey = isUploadWidget ? 'url' : ''
          const parsedValue = isUploadWidget ? rawValue?.[uploadValueKey] ?? null : rawValue
          if (!isNil(parsedValue)) {
            result[item.key] = parsedValue
          }
        } else if (item.widget === 'sam') {
          const maskDataUrl = (rawValue as SamCallbackData)?.maskImgUrl
          if (maskDataUrl) {
            const blob = await fetch(maskDataUrl).then((r) => r.blob())
            const maskImage = new File([blob], 'mask.png', {
              type: 'image/png',
            })
            const filepath = `${nanoid()}/mask.png`
            const uploadRes = await publicUpload({
              filepath,
              file: maskImage,
            })

            result[item.key] = uploadRes?.url
          }
        } else {
          result[item.key] = rawValue
        }
      }
      return result
    },
    [activeTemplateInputWidgets],
  )

  const promptRequired =
    currentCreationMode?.tags.includes('text') &&
    mode !== CreationModeEnum.Extend &&
    mode !== CreationModeEnum.Upscale &&
    mode !== CreationModeEnum.AnimateHD

  const disableGenerate =
    loading ||
    (uploadRequired && (!fileId || uploading)) ||
    (keyframeRequired && (!hasKeyframeFile || keyframeFileUploading)) ||
    (currentCreationMode?.tags?.includes('mask') && !samRef.current?.maskImgUrl) ||
    (promptRequired && !prompt) ||
    hasEmptyTemplateFields

  useEffect(() => {
    if (fileType === 'video') {
      setDurationByUrl(creation?.video_url ?? file?.url ?? '')
    }
  }, [file, fileType, setDurationByUrl, creation])

  const resetInput = useCallback(
    (resetPrompt = true) => {
      setFile(null)
      setKeyframeFiles({
        first: null,
        second: null,
        third: null,
      })
      setTemplateInputs({})
      if (resetPrompt) {
        setPrompt('')
      }
    },
    [setPrompt, setFile, setKeyframeFiles, setTemplateInputs],
  )

  const { data: defaultSettings } = useDefaultSettings()

  const resetSettings = useCallback(() => {
    setSettings(defaultSettings)
    setStyle('')
  }, [defaultSettings, setSettings, setStyle])

  useEffect(() => {
    // update default settings
    resetSettings()
  }, [resetSettings])

  const resetAll = useCallback(() => {
    resetSettings()
    resetInput(true)
    setCreationInput((prev) => ({
      ...prev,
      creation: undefined,
    }))
  }, [resetInput, resetSettings, setCreationInput])

  const startCoolDown = useCallback(() => {
    setCoolDown(
      dayjs()
        .add(coolDownTime + 1, 'second')
        .toDate(),
    )
  }, [setCoolDown, coolDownTime])

  const hasInputError = useCallback(
    ({ mode, prompt, fileId, uploading }: InputSchema) => {
      if (
        mode === CreationModeEnum.Create ||
        mode === CreationModeEnum.CreateHD ||
        mode === CreationModeEnum.CreateImg
      ) {
        return prompt ? '' : 'Please input your prompt'
      }
      if (mode === CreationModeEnum.Animate || mode === CreationModeEnum.AnimateHD) {
        if (showKeyframeConditioning) {
          return hasKeyframeFile
            ? ''
            : keyframeFileUploading
              ? 'Please wait for the image to finish uploading'
              : 'Please upload your image'
        }
        return fileId ? '' : uploading ? 'Please wait for the image to finish uploading' : 'Please upload your image'
      }
      if (mode === CreationModeEnum.Repaint) {
        if (fileId && prompt) {
          return ''
        }
        if (!fileId) {
          return uploading ? 'Please wait for the video to finish uploading' : 'Please upload your video'
        }
        return 'Please input your prompt'
      }
    },
    [showKeyframeConditioning, hasKeyframeFile, keyframeFileUploading],
  )

  const checkKeyframeResolution = useCallback(
    async (newFile?: File) => {
      if (!AFC_REQUIRE_SAME_RESOLUTION) {
        return true
      }
      if (showKeyframeConditioning && modesRequiresKeyframe.includes(mode)) {
        const first = keyframeFiles.first
        const second = keyframeFiles.second
        const third = keyframeFiles.third
        const validFiles = [first, second, third].filter((item) => item?.fileId)

        let targetWidth = validFiles[0]?.width
        let targetHeight = validFiles[0]?.height

        if (newFile) {
          const url = URL.createObjectURL(newFile)
          const { width, height } = await getImageResolution(url)
          targetWidth = width
          targetHeight = height
        }

        if (validFiles.some((item) => item?.width !== targetWidth || item?.height !== targetHeight)) {
          confirm({
            title: 'Image dimensions mismatch',
            message: 'Please ensure that all uploaded images have the same dimensions.',
            okText: 'Confirm',
            cancelButtonProps: {
              className: 'hidden',
            },
          })
          return false
        }
        return true
      }
      return true
    },
    [showKeyframeConditioning, keyframeFiles, mode],
  )

  useEffect(() => {
    checkKeyframeResolution().catch(console.error)
  }, [checkKeyframeResolution])

  const handleSubmit = useCallback(
    async (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault()
      if (loadingRef.current === true) {
        return
      }
      const inputError = hasInputError({
        mode,
        prompt: prompt,
        fileId,
        uploading,
      })

      if (inputError) {
        toast({
          title: inputError,
        })
        return
      }

      const params: GenerationParams = cloneDeep(
        merge(mlArgs, {
          prompt: [prompt, promptSuffix].filter(Boolean).join(', '),
          settings,
          is_public: !canGeneratePrivate || !creationInputPrivateMode,
        }),
      )

      if (showMotionMode) {
        params.use_ff_cond = !!params.settings?.use_ff_cond
        delete params.settings?.use_ff_cond
      }

      if (showEnhancePrompt) {
        params.is_enable_prompt_enhancer = enhancePrompt
      }

      if (fileType === 'video' && creation?.creation_id) {
        params.parent_id = creation.creation_id
      }

      if (!currentCreationMode?.tags?.includes('text')) {
        delete params.prompt
      }

      if (file?.fileId && ['video', 'image'].includes(fileType)) {
        params.config =
          fileType === 'video'
            ? {
                source_video: withFirebaseBucketPrefix(file?.fileId),
              }
            : {
                source_image: withFirebaseBucketPrefix(file?.fileId),
              }

        params.config.input_content_type = fileType
        if (fileType === 'video') {
          const { width, height } = await getVideoResolution(file.url ?? '')
          params.config.input_width = width
          params.config.input_height = height
        } else {
          const { width, height } = await getImageResolution(file.url ?? '')
          params.config.input_width = width
          params.config.input_height = height
        }
      } else {
        params.config = {
          ...creation?.config,
        }
        delete params.config.image_number
        if (mode === CreationModeEnum.Repaint) {
          params.config = {
            source_video: outputVideo ?? '',
          }
        }
      }

      if (fileType === 'video' && params.config?.source_video) {
        params.settings = params.settings ?? {}
        const url = creation?.video_url ?? file?.url ?? ''
        if (url) {
          params.settings.duration = await getDurationByUrl(url)
        }
      }

      if (params.settings?.camera_movement) {
        if (showCameraMovement) {
          params.config.camera_movement = params.settings.camera_movement
        }
        delete params.settings.camera_movement
      }

      if (showFastGen) {
        if (params.settings && Object.keys(params.settings ?? {}).includes('fast_gen')) {
          params.config.fast_gen = params.settings.fast_gen
          delete params.settings.fast_gen
        }
      }

      const useSam = mode === CreationModeEnum.Repaint && samRef.current && (samRef.current.clicks ?? [])?.length > 0
      if (mode === CreationModeEnum.Repaint) {
        if (useSam) {
          params.config = params.config ?? {}
          const clicks = samRef.current?.clicks ?? []
          const inpaintParams = params as InpaintingParams
          inpaintParams.config!.clicks = [
            clicks.map((item) => [
              // item.x,
              // item.y,
              Math.round(item.x),
              Math.round(item.y),
            ]), // coords
            clicks.map((item) => item.clickType), // clickType
          ]

          // use converted_video if available
          inpaintParams.config!.source_video = samRef.current?.converted_video ?? inpaintParams.config!.source_video
        } else {
          delete params.config.sam
          // @ts-ignore
          delete params.config.clicks
        }
      }

      // if (useNumbericAspectRatio && params.settings) {
      //   params.settings.aspect_ratio = (16 / 9) as any
      // }

      if (modesRequiresKeyframe.includes(mode) && showKeyframeConditioning) {
        params.config = params.config ?? {}
        if (enableAfc) {
          delete params.config.source_image
          delete params.config.input_content_type
          delete params.config.image_number
          params.config.source_images = [
            keyframeFiles.first?.fileId ?? null,
            keyframeFiles.second?.fileId ?? null,
            keyframeFiles.third?.fileId ?? null,
          ]

          const checkRes = await checkKeyframeResolution()
          if (!checkRes) {
            return
          }

          const first = keyframeFiles.first
          const second = keyframeFiles.second
          const third = keyframeFiles.third
          const validFiles = [first, second, third].filter((item) => item?.fileId)
          params.config.input_width = validFiles[0]?.width
          params.config.input_height = validFiles[0]?.height
        } else {
          params.config.source_image = keyframeFiles.first?.fileId ?? null
        }
      }

      setLoading(true)
      loadingRef.current = true
      let generation: GenerationResult | null = null
      try {
        if (onSubmit) {
          generation = await onSubmit(params)
        } else {
          if (mode === CreationModeEnum.Repaint) {
            if (useSam) {
              generation = await inpaint(params as InpaintingParams)
            } else {
              generation = await repaint(params)
            }
          } else if (mode === CreationModeEnum.Create || mode === CreationModeEnum.Animate) {
            generation = await generate(params)
          } else if (CreationModeEnum.CreateHD === mode) {
            if (creationInput.version === ModelVersion.TWO) {
              // model2.0 fix duration of 6s
              params.settings = params.settings ?? {}
              if (showFakeDuration) {
                params.settings.duration = 6
              }
              // params.settings.aspect_ratio = DEFAULT_ASPECT_RATIO
              generation = await gen2Text2Video(params)
            } else {
              generation = await generateBB8(params)
            }
          } else if (CreationModeEnum.AnimateHD === mode) {
            if (enableAfc) {
              generation = await generateAfc(params)
            } else {
              if (creationInput.version === ModelVersion.TWO) {
                params.settings = params.settings ?? {}
                if (showFakeDuration) {
                  params.settings.duration = 6
                }
                generation = await gen2Image2Video(params)
              } else {
                generation = await generateBB8(params)
              }
            }
          } else if (CreationModeEnum.Extend === mode) {
            const extendParams = omit(params, ['settings']) as ExtendGenerationParams
            extendParams.config = {
              source_video: withFirebaseBucketPrefix(file?.fileId ?? '') ?? '',
              extend_duration: getNextExtendDuration(params.settings?.duration ?? 0),
              camera_movement: params.config?.camera_movement,
            }
            generation = await extend(extendParams)
          } else if (CreationModeEnum.Upscale === mode) {
            generation = await upscale(params as UpscaleGenerationParams)
          } else if (mode === CreationModeEnum.CreateImg) {
            delete params.settings?.motion_level
            // delete params.settings?.resolution
            if (creationInput.version === ModelVersion.TWO) {
              generation = await gen2Text2Image(params)
            } else {
              generation = await generateImg(params)
            }
          } else if (activeTemplate) {
            const formattedTemplateInputs = await formatTemplateInputs(templateInputs)
            const templateParams: TemplateGenerationParams = {
              settings: params.settings ?? {},
              config: {
                template_id: activeTemplate.template_id,
                template_inputs: {
                  ...comfyTemplateExtraParams,
                  ...formattedTemplateInputs,
                },
              },
              is_public: params.is_public,
            }

            if (respectTemplateClientName && switches?.template_client) {
              if ((activeTemplate as TemplateDetail)?.template_client_name) {
                templateParams.config.template_client_name = (activeTemplate as TemplateDetail).template_client_name
              }
            }

            templateParams.settings = templateParams.settings ?? {}

            const template_inputs = templateParams.config.template_inputs

            if (showAspectRatio && templateParams.settings) {
              templateParams.settings.aspect_ratio = templateParams.settings.aspect_ratio ?? DEFAULT_ASPECT_RATIO
              template_inputs.aspect_ratio = ASPECT_RATIO_MAP[templateParams.settings.aspect_ratio] ?? undefined
            }

            if (isValidSeed(templateParams.settings?.seed) && showSeed) {
              template_inputs.seed = templateParams.settings?.seed
            }

            if (activeTemplate?.tags?.includes('mask')) {
              const maskImgUrl = samRef.current?.maskImgUrl
              if (maskImgUrl) {
                const blob = await fetch(maskImgUrl).then((r) => r.blob())
                const maskImage = new File([blob], 'mask.png', {
                  type: 'image/png',
                })
                const filepath = `${nanoid()}/mask.png`
                const uploadRes = await publicUpload({
                  filepath,
                  file: maskImage,
                })
                template_inputs.input_mask_image_url = uploadRes?.url
              }
            }
            if (activeTemplate?.tags?.includes('text')) {
              template_inputs.input_prompt = prompt
            }
            // @ts-ignore
            delete templateParams.settings?.file
            // invoke comfyTemplate

            generation = await postTemplateJob(templateParams)
          }
        }
      } catch (error) {
        console.error(error)
      }
      setLoading(false)
      loadingRef.current = false
      if (!isNil(generation?.generation_id)) {
        refreshJobs()
        const shouldOpenProductHunt = getLocalStorage(CACHE_KEY_PRODUCTHUNT_DIALOG) === null
        if (shouldOpenProductHunt) {
          setTimeout(() => {
            setLocalStorage(CACHE_KEY_PRODUCTHUNT_DIALOG, false)
            setProductHuntDialogOpen(true)
          }, 3 * 1000)
        }
        await onGenerate?.(generation?.generation_id ?? '')
        startCoolDown()
        tryOpenSurvey()
      }
      if (refreshCredit) {
        await refreshCredit()
      }
    },
    [
      onSubmit,
      prompt,
      mode,
      settings,
      mlArgs,
      // useNumbericAspectRatio,
      fileId,
      creation,
      outputVideo,
      checkKeyframeResolution,
      switches,
      onGenerate,
      currentCreationMode,
      fileType,
      enhancePrompt,
      showEnhancePrompt,
      uploading,
      startCoolDown,
      refreshJobs,
      getDurationByUrl,
      creationInputPrivateMode,
      canGeneratePrivate,
      refreshCredit,
      keyframeFiles,
      comfyTemplateExtraParams,
      showAspectRatio,
      showFastGen,
      showFakeDuration,
      showSeed,
      hasInputError,
      showKeyframeConditioning,
      templateInputs,
      showMotionMode,
      enableAfc,
      creationInput,
      file,
      showCameraMovement,
      setProductHuntDialogOpen,
      promptSuffix,
      activeTemplate,
      formatTemplateInputs,
      respectTemplateClientName,
      tryOpenSurvey,
    ],
  )

  useEffect(() => {
    // reset file and prompt when mode changed
    if (
      creation &&
      (creation.type === 'generation' || creation.type === 'gen2') &&
      creation.output_type === 'image' &&
      creationInput.img
    ) {
      const newFile: InnerUploadFile = {
        thumbnailUrl: creationInput.img || '',
        url: creationInput.img || '',
        fileId: creationInput.img,
      }

      if (showKeyframeConditioning) {
        setKeyframeFiles({
          first: newFile,
          second: null,
          third: null,
        })
      } else {
        setFile(newFile)
      }

      getImageResolution(newFile.url)
        .then(({ width, height }) => {
          const newFileWithResolution = {
            ...newFile,
            width,
            height,
          }
          if (showKeyframeConditioning) {
            setKeyframeFiles((old) => {
              if (old?.first && old?.first === newFile) {
                return {
                  first: newFileWithResolution,
                  second: null,
                  third: null,
                }
              }
              return old
            })
          } else {
            setFile(newFileWithResolution)
          }
        })
        .catch(console.error)
      // Todo complete image2Video
    } else {
      // resetInput()
    }
  }, [creation, resetInput, setKeyframeFiles, setFile, creationInput.img, showKeyframeConditioning])

  const durationOptions = useDurationOptions()

  const updateSettings = useCallback(
    (settings: GenerationSetting) => {
      whisper('version: ', creationInput.version)
      const newSettings = {
        ...defaultSettings,
        ...(pick(settings, validKeys) as GenerationSetting),
      }
      if (mode === CreationModeEnum.Repaint && creation?.settings?.duration) {
        newSettings.duration = Math.min(4, creation?.settings?.duration) // TODO: refactor duration
      }

      newSettings.duration = durationOptions.some((item) => item.second === newSettings.duration)
        ? newSettings.duration
        : defaultSettings.duration

      if (showDuration) {
        newSettings.duration = newSettings.duration || defaultSettings.duration
      } else {
        delete newSettings.duration
      }

      if (mode === CreationModeEnum.CreateImg) {
        // resolution max 1080p
        newSettings.resolution = DEFAULT_IMAGE_RESOLUTION
      } else if (newSettings.resolution && newSettings.resolution > 720) {
        newSettings.resolution = DEFAULT_RESOLUTION
      }

      if (showAspectRatio && !newSettings.aspect_ratio) {
        newSettings.aspect_ratio = defaultSettings.aspect_ratio ?? DEFAULT_ASPECT_RATIO
      }

      setSettings(newSettings)
    },
    [
      mode,
      creation,
      durationOptions,
      setSettings,
      validKeys,
      showAspectRatio,
      showDuration,
      defaultSettings,
      creationInput.version,
    ],
  )

  useEffect(() => {
    if (creation?.settings) {
      updateSettings(omit(creation.settings, ['seed']))
      // updateSettings(creation.settings)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creation])

  useEffect(() => {
    updateSettings(settings)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, activeTemplate, creationInput.version])

  const renderControls = () => {
    const controlsProps: CreationInputControlsProps = {
      uploading,
      setUploading,
      expanded: Boolean(expanded),
      keyframeFiles,
      setKeyframeFiles,
      fileId,
      file,
      setFile,
      creation,
      onSamChange,
      onDeleteFile,
      textarea,
      keyframesUploading,
      setKeyframesUploading,
      enableAfc,
      setEnableAfc,
      beforeUpload: checkKeyframeResolution,
      templateInputs,
      setTemplateInputs,
    }

    const mode2ControlsMap: Record<CreationModeEnumOrUsecaseEnum, typeof T2vControls> = {
      [CreationModeEnum.Create]: T2vControls,
      [CreationModeEnum.CreateHD]: T2vControls,
      [CreationModeEnum.Animate]: I2vControls,
      [CreationModeEnum.AnimateHD]: I2vControls,
      [CreationModeEnum.CreateImg]: T2iControls,
      [CreationModeEnum.Repaint]: V2vControls,
      [CreationModeEnum.Extend]: ExtendControls,
      [CreationModeEnum.Upscale]: UpscaleControls,
    }

    const ControlsComponent = activeTemplateId ? TemplateControls : mode2ControlsMap[mode] ?? null
    if (Boolean(ControlsComponent)) {
      return <ControlsComponent {...controlsProps} />
    }

    return null
  }

  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (!textareaRef.current || !focusing) return
    setTimeout(() => {
      textareaRef.current?.focus()
    }, 100)
  }, [focusing])

  const onSamChange = useCallback(
    (data?: SamCallbackData) => {
      samRef.current = data

      const useSam = samRef.current && (samRef.current.clicks ?? [])?.length > 0
      if (useSam) {
        // TODO: refactor duration
        setSettings((prev) => ({
          ...prev,
          duration: Math.min(2, prev.duration ?? 4),
        }))
      }
    },
    [setSettings],
  )

  const onDeleteFile = useCallback(() => {
    if (outputVideo) {
      setCreationInput((prev) => ({ ...prev, creation: undefined }))
    } else {
      resetInput(false)
    }
  }, [outputVideo, resetInput, setCreationInput])

  useEffect(() => {
    samRef.current = undefined
  }, [fileId])

  const ChevronIcon = expanded ? ChevronDown : ChevronTop

  const switchEnhancePromptControl = useMemo(() => {
    if (!showEnhancePrompt) {
      return undefined
    }

    const disabled = !prompt
    return (
      <div className={cls('flex items-center gap-2 bg-surface rounded-md h-6 px-1')}>
        <Switch size='sm' disabled={disabled} checked={enhancePrompt} onCheckedChange={setEnhancePrompt} />
        <span className={cls('text-body-sm', disabled && 'text-text-disabled')}>Enhance prompt</span>
      </div>
    )
  }, [showEnhancePrompt, prompt, enhancePrompt, setEnhancePrompt])

  const textarea = useMemo(
    () => (
      <Textarea
        ref={textareaRef}
        value={prompt}
        disabled={disableInput && !isBelowMd}
        placeholder={
          disableInput ? '' : placeholder ?? placeholderMap[mode]?.short ?? 'Describe the scene of the video you want'
        }
        aria-label='creation-input-textarea'
        className={cls(className, isBelowMd && !expanded ? 'pl-4 pr-0' : '', hideTextArea ? 'hidden' : '')}
        suffix={switchEnhancePromptControl}
        onChange={(e: any) => setPrompt(e.target.value.slice(0, MAX_LENGTH))}
        onInput={(e) => {
          if (prompt.length === MAX_LENGTH) {
            toast({
              title: `Up to ${MAX_LENGTH} characters`,
            })
          }
        }}
        onFocus={() => {
          if (!hasSignIn) {
            checkAuth()
            return
          }
          setCreationInput((prev) => ({
            ...prev,
            focusing: true,
            expanded: true,
          }))
          // track('input:creation:focus')
        }}
        onBlur={() => {
          setCreationInput((prev) => ({ ...prev, focusing: false }))
          // track('input:creation:blur')
        }}
        onKeyDown={async (e) => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e?.preventDefault()
            if (!cooldown || dayjs().isAfter(dayjs(cooldown))) {
              await handleSubmit().catch(console.error)
            }
          }
        }}
      />
    ),
    [
      checkAuth,
      className,
      cooldown,
      disableInput,
      expanded,
      handleSubmit,
      setPrompt,
      hideTextArea,
      isBelowMd,
      mode,
      placeholder,
      switchEnhancePromptControl,
      setCreationInput,
      prompt,
      hasSignIn,
    ],
  )

  return (
    <form
      ref={ref}
      className={cls(
        'relative backdrop-blur-[25px] bg-surface md:min-w-[642px] w-full max-w-[1000px] md:max-w-[calc(min(1000px,90%))] max-h-[calc(100vh-240px)] md:max-h-[calc(100vh-160px)] rounded-t-lg md:rounded-[24px] mx-auto flex flex-col pt-0 border-2 border-b-0 md:border-b-2 border-solid border-border pointer-events-auto',
        containerClassName,
      )}
      data-testid='creation-input'
      onSubmit={preventDefault as any}
    >
      <div className='relative p-3' aria-label='creation input header'>
        <div
          className='relative size-full flex items-center justify-between'
          aria-label='creation input header content'
        >
          <div className='flex items-center gap-2'>
            <CreationModeDialog
              className={cls(
                'shrink-0 h-10 rounded-[24px] ml-0 shadow-none hover:opacity-90 active:opacity-90 bg-transparent border border-b-2 border-border',
              )}
            />
            <CreationModeTutorialButton />
            <ModelVersionPicker id='creation-model-version-button' />
          </div>
          <div className='absolute right-0 top-0 flex flex-col h-full justify-start items-start'>
            <Button
              variant='outline'
              className='size-8 min-w-8 min-h-8 max-h-8 border border-border rounded-md flex items-center justify-center p-0'
              tooltip='Clear'
              tooltipProps={{
                side: 'bottom',
                align: 'end',
              }}
              onClick={resetAll}
            >
              <IconDelete className='size-5 text-icon' />
            </Button>
          </div>
          <Button
            className={cls(
              'absolute border-none top-0 left-[50%] translate-x-[-50%] text-text-subdued bg-transparent w-16 h-5 p-0 m-0 hover:bg-surface-subdued rounded-full',
              expanded ? 'hidden md:flex' : 'hidden',
            )}
            type='button'
            variant='transparent'
            aria-label='Toggle Expand'
            aria-expanded={expanded}
            onClick={fold}
          >
            <ChevronIcon className='size-6 text-icon-subdued' />
          </Button>
        </div>
      </div>
      <div
        className={cls(
          'flex pb-0 w-full min-h-0 overflow-y-auto no-scrollbar overscroll-contain bg-surface-base border-border border-dashed md:rounded-b-[24px] overflow-hidden',
          toolbarDirection === 'up' ? 'flex-col' : 'flex-col-reverse',
        )}
        aria-label='creation input textarea container'
      >
        {realShowCreationModeTutorial ? <CreationModeTutorial onDismiss={dismissTutorial} /> : renderControls()}
        <div
          className={cls(
            'flex flex-row items-center p-3 pt-1 bg-surface gap-3',
            realShowCreationModeTutorial && 'hidden',
          )}
        >
          <CreationSetting
            settings={settings}
            setSettings={setSettings}
            mlArgs={mlArgs}
            setMLArgs={setMLArgs}
            style={style}
            setStyle={setStyle}
          />
          <div className='ml-auto'>
            <VisibilitySetting />
          </div>
          <ButtonGenerate
            showTooltipWhenDisabled
            className=''
            cooldown={cooldown}
            disabled={disableGenerate}
            loading={loading}
            settings={settings}
            creationInput={creationInput}
            cooldownTooltip={cooldownTooltip}
            hideFreePrice={(
              [CreationModeEnum.Extend, CreationModeEnum.Upscale] as CreationModeEnumOrUsecaseEnum[]
            ).includes(mode)}
            onClick={() => handleSubmit?.()}
          />
        </div>
        <div
          className={cls(
            'hidden w-full justify-center p-3 pt-1 rounded-b-md bg-surface h-13',
            realShowCreationModeTutorial && 'flex',
          )}
        >
          <Button variant='primary' onClick={dismissTutorial}>
            Start Creating
          </Button>
        </div>
      </div>
      <LoginMask className='hidden md:flex' />
      {expanded ? null : <CreationInputMask onClick={unfold} />}
    </form>
  )
}
