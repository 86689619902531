import {
  ReactElement,
  ReactNode,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDropzone, Accept } from 'react-dropzone'
import { isNil } from 'lodash-es'
import { InnerUploadFile, PoNVoid, UploadParams } from '@/types'
import RadialProgress from '@/components/radial-progress'
import Button from '@/components/button'
import LoadingButton from '@/components/loading-button'
import { toast } from '@/components/ui/use-toast'
import style from './style.module.scss'
import IconTrash from '@haiper/icons-svg/icons/outline/delete.svg'
import useUpload from '@/hooks/useUpload'
import IconImage from '@haiper/icons-svg/icons/outline/image-square-2-mountains.svg'
import IconVideo from '@haiper/icons-svg/icons/outline/video-clip.svg'

import { stopPropagation, cls, preventDefault, getVideoDuration, fileSize } from '@/utils'
import { ALLOWED_IMAGE_EXTENSIONS, ALLOWED_VIDEO_EXTENSIONS, MAX_FILE_SIZE } from '@/constants'
import Dialog from '../dialog'
import Image from '../image'

export interface GSUploadProps {
  id?: string
  name?: string
  className?: string
  multiple?: boolean
  file: InnerUploadFile | null
  fileType: 'image' | 'video'
  uploading?: boolean
  disabled?: boolean
  maxFileSize?: number
  minDuration?: number
  maxDuration?: number
  onProgress?: (percent: number) => PoNVoid
  setUploading?: (uploading: boolean) => void
  onChange?: (file: InnerUploadFile | null) => PoNVoid
  onError?: (error: Error) => PoNVoid
  customUpload?: (params: UploadParams) => Promise<void>
  customPreview?: (file: InnerUploadFile) => ReactNode
  emptyText?: string | ReactElement
  beforeUpload?: (file: File) => Promise<boolean>
  emptyIcon?: typeof IconImage
  accept?: Accept
  allowedFileSizeDelta?: number // in bytes
  allowedDurationDelta?: number // in seconds
}

export interface GSUploadRef {
  inputRef: RefObject<HTMLInputElement>
}

const GSUpload = forwardRef<GSUploadRef, GSUploadProps>(
  (
    {
      id,
      accept,
      className,
      name,
      file,
      fileType,
      uploading,
      maxFileSize = MAX_FILE_SIZE,
      minDuration,
      maxDuration,
      disabled,
      customUpload,
      setUploading,
      beforeUpload,
      emptyText,
      emptyIcon,
      onProgress,
      onChange,
      onError,
      customPreview,
      allowedFileSizeDelta = 1024 * 100, // 100KB
      allowedDurationDelta = 0.1, // 0.1s
    }: GSUploadProps,
    ref,
  ) => {
    const fileRef = useRef<File>()
    const [progress, setProgress] = useState(0)
    const [error, setError] = useState<Error | null>(null)
    const [innerUploading, setInnerUploading] = useState(false)
    const realUploading = uploading ?? innerUploading

    const { upload } = useUpload()
    const fileTypeRef = useRef(fileType)

    const [inputFileError, setInputFileError] = useState('')

    const inputFileRequirementMsg = useMemo(() => {
      if (fileType === 'video') {
        const durationMsg =
          maxDuration && minDuration
            ? `between ${minDuration}-${maxDuration} seconds long`
            : maxDuration
              ? `less than ${maxDuration} seconds long`
              : minDuration
                ? `greater than ${minDuration} seconds long`
                : ''
        const sizeMsg = maxFileSize ? `less than ${fileSize(maxFileSize)}` : ''
        const msg = [durationMsg, sizeMsg].filter(Boolean).join(', and ')
        return msg ? `Videos must be ${msg}.` : ''
      } else if (fileType === 'image') {
        return maxFileSize ? `Images must be less than ${fileSize(maxFileSize)}.` : ''
      }
      return ''
    }, [fileType, minDuration, maxDuration, maxFileSize])

    useEffect(() => {
      fileTypeRef.current = fileType
    }, [fileType])

    const realUpload = customUpload ?? upload

    const doUpload = useCallback(
      async (fileObj: File) => {
        setUploading?.(true)
        setInnerUploading(true)
        setProgress(0)
        onProgress?.(0)
        setError(null)
        await realUpload({
          file: fileObj,
          onProgress: ({ percent }) => {
            setProgress(percent)
            onProgress?.(percent)
          },
          onError: (err) => {
            setUploading?.(false)
            setInnerUploading(false)
            setError(err)
          },
          onSuccess(data) {
            setUploading?.(false)
            setInnerUploading(false)
            if (fileTypeRef.current !== fileType) {
              return
            }
            onChange?.({
              ...data,
              status: 'done',
            })
          },
        })
      },
      [realUpload, setUploading, onChange, fileType, onProgress],
    )

    const onDrop = useCallback(
      async (acceptedFiles: File[], fileRejections: any[]) => {
        // Do something with the files
        const file = acceptedFiles?.[0]
        if (file) {
          const beforeUploadRes = await beforeUpload?.(file)
          if (beforeUploadRes === false) {
            return
          }
          let hasError = !!(maxFileSize && file.size - allowedFileSizeDelta > maxFileSize)
          if (fileType === 'video' && (minDuration || maxDuration)) {
            const url = URL.createObjectURL(file)
            const duration = await getVideoDuration(url)
            hasError = !!(
              hasError ||
              (minDuration && duration + allowedDurationDelta < minDuration) ||
              (maxDuration && duration - allowedDurationDelta > maxDuration)
            )
          }
          if (hasError) {
            if (onError) {
              onError(
                // new Error(`Uploaded files cannot exceed ${maxFileSizeStr}`),
                new Error(inputFileRequirementMsg),
              )
            } else {
              // toast({
              //   title: `Uploaded files cannot exceed ${maxFileSizeStr}`,
              // })
              setInputFileError(inputFileRequirementMsg)
            }
            return
          }
        } else {
          toast({
            color: 'error',
            title: 'Please upload a valid file',
            content: fileRejections[0]?.errors[0]?.message ?? '',
          })
          return
        }
        fileRef.current = file
        if (!isNil(file)) {
          doUpload(file)
        }
      },
      [
        doUpload,
        beforeUpload,
        onError,
        inputFileRequirementMsg,
        minDuration,
        maxDuration,
        maxFileSize,
        fileType,
        allowedDurationDelta,
        allowedFileSizeDelta,
      ],
    )

    const realAccept = useMemo(() => {
      if (accept) {
        return accept
      }
      return fileType === 'image'
        ? {
            // 'image/*': ALLOWED_IMAGE_EXTENSIONS,
            'image/png': ALLOWED_IMAGE_EXTENSIONS,
            'image/jpeg': ALLOWED_IMAGE_EXTENSIONS,
          }
        : {
            'video/mp4': ALLOWED_VIDEO_EXTENSIONS,
            // 'video/quicktime': ALLOWED_VIDEO_EXTENSIONS,
          }
    }, [accept, fileType])

    const { getRootProps, getInputProps, isDragReject } = useDropzone({
      onDrop,
      disabled,
      accept: realAccept,
    })

    const rootProps = getRootProps()
    const inputProps = getInputProps()

    const inputRef = (inputProps as any).ref

    useImperativeHandle(
      ref,
      () => {
        return {
          inputRef,
        }
      },
      [inputRef],
    )

    const tooltip = fileType === 'image' ? 'Upload Image' : 'Upload Video'

    const UploadIcon = emptyIcon ?? (fileType === 'image' ? IconImage : IconVideo)

    return (
      <>
        <div
          id={id}
          className={cls(
            'relative border border-b-2 border-border hover:border-border-hover rounded-lg w-42 h-[98px] text-center bg-surface hover:bg-surface-hover overflow-hidden',
            // file && 'border-solid',
            disabled && 'hover:bg-surface',
            error && 'hover:bg-surface',
            className,
          )}
          {...rootProps}
        >
          <input name={name} className='hidden' multiple={false} {...inputProps} />
          <div
            className={cls(
              'block text-center size-full cursor-pointer',
              disabled ? 'cursor-default' : '',
              isDragReject ? 'opacity-20' : '',
            )}
          >
            <div
              className={cls(
                'group size-full flex flex-col items-center justify-center relative',
                style.uploadStatus,
                file ? style.succeed : '',
              )}
            >
              {file ? (
                <>
                  {customPreview ? (
                    customPreview(file)
                  ) : (
                    <Image
                      src={file?.thumbnailUrl ?? ''}
                      alt='thumbnail'
                      className='cursor-default w-full md:w-auto h-full md:h-auto object-contain max-w-full max-h-full'
                    />
                  )}
                  <div
                    className='absolute inset-0 flex items-center justify-center pointer-events-auto cursor-default md:hidden group-hover:md:flex bg-black/60'
                    onClick={stopPropagation as any}
                  >
                    <Button
                      type='button'
                      aria-label='Delete'
                      variant='transparent'
                      className={cls('size-10 p-2 border-none rounded-sm bg-transparent hover:bg-transparent')}
                      onClick={(e) => {
                        e?.preventDefault()
                        e?.stopPropagation()
                        onChange?.(null)
                        if (fileRef.current) {
                          fileRef.current = undefined
                        }
                      }}
                    >
                      <IconTrash className='size-4 md:size-6 text-icon-on-color' />
                    </Button>
                  </div>
                </>
              ) : realUploading ? (
                <RadialProgress progress={progress} />
              ) : error ? (
                <LoadingButton
                  variant='outline'
                  className='bg-surface group-hover:bg-surface-hover rounded-xl text-body-md h-8'
                  type='button'
                  onClick={async (e) => {
                    e?.preventDefault()
                    e?.stopPropagation()
                    if (fileRef.current) {
                      await doUpload(fileRef.current)
                    }
                  }}
                >
                  Retry
                </LoadingButton>
              ) : (
                <div className='flex flex-col gap-1'>
                  <UploadIcon className={cls('mx-auto text-icon size-6', disabled && 'opacity-50')} />
                  <div className={cls('text-body-md text-text', disabled && 'text-text-disabled')}>
                    {emptyText ?? tooltip}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className={cls(
              'absolute inset-0 bg-white z-20 opacity-80 items-center justify-center',
              isDragReject ? 'flex' : 'hidden',
            )}
          >
            <Image
              src='/assets/forbidden.svg'
              alt='forbidden'
              width={120}
              height={120}
              className='max-w-[50%] max-h-[50%]'
            />
          </div>
        </div>
        <Dialog
          open={Boolean(inputFileError)}
          title={fileType === 'video' ? 'Invalid video file' : 'Invalid image file'}
          className='md:w-100'
          footer={
            <Button
              type='button'
              variant='primary'
              className='w-full rounded-md'
              onClick={() => {
                setInputFileError('')
              }}
            >
              OK
            </Button>
          }
          onOpenChange={(open) => {
            if (!open) {
              setInputFileError('')
            }
          }}
          onClick={preventDefault as any}
        >
          <div className='pt-1 pb-8 text-text-subdued'>{inputFileError}</div>
        </Dialog>
      </>
    )
  },
)

GSUpload.displayName = 'GSUpload'

export default GSUpload
